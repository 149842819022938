var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import Cookies from 'js-cookie';
export default function pushYM(_a) {
    var eventName = _a.eventName, goalParams = _a.goalParams;
    var YM_ID = process.env.REACT_APP_YANDEX_ID;
    if (!YM_ID) {
        return;
    }
    if (typeof ym === 'function') {
        ym(YM_ID, 'reachGoal', eventName, __assign(__assign({}, goalParams), { user_id: Cookies.get('profileId') || undefined }));
    }
}
;
